import Card from "antd/es/card/Card";
import styled, { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
body {
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
  }
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
}
*::-webkit-scrollbar {
  width: 0.5px;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}

`;

export const Container = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
  display: flex;
  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

export const LightButton = styled.button`
  border-radius: 4px;
  background: ${({ primary }) => (primary ? "#FFFFFF" : "#FFFFFF")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "12px 64px" : "10px 20px")};
  color: #292f6f;
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  &:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #ffffff;
    background-color: ${({ primary }) => (primary ? "#292F6F" : "#4B59F7")};
  }
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

export const LightButtonOutline = styled.button`
  border-radius: 10px;
  white-space: nowrap;
  width: 100%;
  background: ${({ primary }) => (primary ? "#FFFFFF" : "#FFFFFF")};
  padding: ${({ big }) => (big ? "12px 64px" : "10px 20px")};
  color: #292f6f;
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: 1px solid #292f6f;
  cursor: pointer;
  &:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #ffffff;
    background-color: ${({ primary }) => (primary ? "#292F6F" : "#292F6F")};
  }
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

export const LightButtonOutlineHero = styled.button`
  border-radius: 10px;
  white-space: nowrap;
  width: 100%;
  background-color: transparent;
  padding: ${({ big }) => (big ? "12px 64px" : "10px 20px")};
  color: #ffffff;
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: #ffffff;
  border: 1px solid #ffffff;
  cursor: pointer;
  &:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #292f6f;
  }
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

export const DarkButton = styled.button`
  border-radius: 4px;
  background: ${({ primary, blue }) =>
    primary ? "#FFFFFF" : blue ? "#292F6F" : "#292F6F"};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "12px 64px" : "10px 20px")};
  color: ${({ blue }) => (blue ? "#FFFFFF" : "#292f6f")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  width: 100%;
  &:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: "#ffffff";
    background-color: ${({ primary, blue }) =>
      primary ? "#292F6F" : blue ? "#292F6F" : "#4B59F7"};
  }
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

export const PlainText = styled.p`
  color: ${({ dark, blue }) =>
    dark ? "#282930" : blue ? "#292F6F" : "#fafaff"};
  font-weight: 300;
  font-size: 16px;
`;

export const StyledCard = styled(Card)`
  background-color: #282930;
  color: #fafaff;
  font-size: 16px;
  height: 450px;
  margin-bottom: 30px;
  padding: 0px 10px;

  @media screen and (max-width: 960px) {
    margin: 20px;
  }
`;

export const DataCopy1Icon = styled.img`
  width: 80px;
  height: 80px;
`;

export const LittleMarg = styled.div`
  padding: 30px 30px 30px 25px;
  border-left: 2px dashed #282930;
`;

export const SectionSpacer = styled.div`
  margin-top: 100px;
  margin-bottom: 100px;
`;

export const StyledForm = styled.form`
  padding: 20px;
`;

export const StyledLabel = styled.label`
  display: block;
  margin-bottom: 5px;
  text-align: left;
  font-weight: bold;
  color: ${(props) => (props.invalid ? "red" : "black")};
`;

export const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
  background: #fafaff;
  &:focus {
    outline: none;
    border-color: #09c93a !important;
    background: #fff;
  }
`;

export const StyledTextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
  background: #fafaff;
  &:focus {
    outline: none;
    border-color: #09c93a !important;
    background: #fff;
  }
`;

export const LargeHeaderDark = styled.h3`
  color: #282930;
  font-size: 28px;
`;

export const MediumHeaderDark = styled.h4`
  color: #282930;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  leading-trim: both;
  text-edge: cap;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
`;

export const SmallHeaderDark = styled.h5`
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  leading-trim: both;
  text-edge: cap;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  text-transform: uppercase;
`;

export const SmallHeaderDark2 = styled.h5`
  font-size: 35px;
  leading-trim: both;
  text-edge: cap;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  text-transform: uppercase;
`;

export const SmallHeaderDark1 = styled.h5`
  font-size: 37px;
  font-family: "Montserrat", sans-serif;
  leading-trim: both;
  text-edge: cap;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  text-transform: uppercase;
`;

export default GlobalStyles;
