import React from "react";
import Icon from "@ant-design/icons";
import { Container, DarkButton } from "../../globalStyles";
import { Item } from "../CustomBg/CustomBg.elements";
import { Col, Row, Divider } from "antd";
import { Link } from "react-router-dom";
import { Space } from "antd";
import { Collapse } from "antd";
import ItemComponent from "./ItemComponent";


const items = [
  {
    key: "1",
    label: <h3>VERISPEAK 12.0 STANDARD SDK</h3>,
    children: (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={6} lg={6}>
          <ItemComponent
            name="VeriSpeak 12.0 Standard SDK"
            src="/voice.png"
            srcModal="/voice.png"
            details="VeriSpeak voice identification technology which is designed to use, the text-dependent speaker recognition algorithm assures system security by checking both voice and phrase authenticity. With the capability to match 1-to-1 (verification) and 1-to-many (identification) modes. VeriSpeak is available as a software development kit that enables the development of stand-alone and Web-based applications on Microsoft Windows, Linux, Mac OS X, iOS and Android platforms.Implementing advanced voice enrollment and voiceprint matching using proprietary sound processing technologies such as – Two-factor authentication with a passphrase – Text-independent algorithm – Text-dependent algorithm VeriSpeak is available as the following SDKs: VeriSpeak Standard SDK: Designed for PC-based, embedded or mobile biometric application development. It includes Voice Matcher and Extractor component licenses, programming samples and tutorials and software documentation."
          />
        </Col>
      </Row>
    ),
  },
  
];

function Voice() {
  return (
    <Container style={{ marginBottom: "50px" }}>
      <Collapse items={items} defaultActiveKey={["1"]} />
    </Container>
  );
}

export default Voice;
