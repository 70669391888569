import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import GlobalStyles from "./globalStyles";
import { Navbar, Footer } from "./components";
import Home from "./pages/HomePage/Home";
import ScrollToTop from "./components/ScrollToTop";
import Projects from "./pages/Projects/Projects";
import Careers from "./pages/Careers/Careers";
import Services from "./pages/Services/Services";
import Contact from "./pages/Contact/Contact";
import About from "./pages/AboutPage/About";
import ProductsSection from "./pages/Products/ProductsSection";

function App() {
  return (
    <Router>
      <GlobalStyles />
      {/* <ScrollToTop /> */}
      <Navbar />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about" exact component={About} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/projects" exact component={Projects} />
        <Route path="/careers" exact component={Careers} />
        <Route path="/products" exact component={ProductsSection} />
        <Route path="/services" exact component={Services} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
