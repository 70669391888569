import React, { useEffect, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import {
  HamburgerIcon,
  Nav,
  NavItem,
  NavLinks,
  NavLogo,
  NavMenu,
  NavbarContainer,
} from "./Navbar.elements";
import "../../index.css";

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const [homeClick, setHomeClick] = useState(false);
  const [servicesClick, setServicesClick] = useState(false);
  const [productsClick, setProductsClick] = useState(false);
  const [careersClick, setCareersClick] = useState(false);
  const [contactsClick, setContactsClick] = useState(false);
  const [projectsClick, setProjectsClick] = useState(false);

  const handleHomeClick = () => {
    setHomeClick(true);
    setProductsClick(false);
    setServicesClick(false);
  };
  const handleServicesClick = () => {
    setHomeClick(false);
    setProductsClick(false);
    setServicesClick(true);
  };
  const handleProductsClick = () => {
    setHomeClick(false);
    setProductsClick(true);
    setServicesClick(false);
  };
  const handleCareersClick = () => {
    setHomeClick(false);
    setProductsClick(false);
    setServicesClick(false);
  };
  const handleContactsClick = () => {
    setHomeClick(false);
    setProductsClick(false);
    setServicesClick(false);
  };

  const handleProjectsClick = () => {
    setHomeClick(false);
    setProductsClick(false);
    setServicesClick(false);
  };

  const handleClick = () => setClick(!click);

  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    // so if the screensize is <= 960px then set button state to false
    if (window.innerwidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  });

  window.addEventListener("resize", showButton);

  return (
    <Nav>
      <NavbarContainer>
        <NavLogo
          to="/"
          onClick={() => {
            closeMobileMenu();
            setHomeClick(false);
            setServicesClick(false);
            setProductsClick(false);
            setCareersClick(false);
            setContactsClick(false);
            setProjectsClick(false);
          }}
        >
          <Logo />
        </NavLogo>
        <HamburgerIcon onClick={handleClick}>
          {click ? <FaTimes /> : <FaBars />}
        </HamburgerIcon>
        <NavMenu onClick={handleClick} click={click}>
          <NavItem onClick={handleHomeClick} homeClick={homeClick}>
            <NavLinks
              to="/about"
              onClick={() => {
                closeMobileMenu();
                setHomeClick(true);
                setServicesClick(false);
                setProductsClick(false);
                setCareersClick(false);
                setContactsClick(false);
                setProjectsClick(false);
              }}
              className="myNav"
              style={{
                color: homeClick ? "Red" : "white",
                fontWeight: "400",
              }}
            >
              {" "}
              ABOUT US
            </NavLinks>
          </NavItem>

          <NavItem onClick={handleServicesClick} servicesClick={servicesClick}>
            <NavLinks
              to="/services"
              onClick={() => {
                closeMobileMenu();
                setHomeClick(false);
                setServicesClick(true);
                setCareersClick(false);
                setProductsClick(false);
                setContactsClick(false);
                setProjectsClick(false);
              }}
              className="myNav"
              style={{
                color: servicesClick ? "Red" : "white",
              }}
            >
              SERVICES
            </NavLinks>
          </NavItem>
          <NavItem onClick={handleProjectsClick} servicesClick={projectsClick}>
            <NavLinks
              to="/projects"
              onClick={() => {
                closeMobileMenu();
                setHomeClick(false);
                setServicesClick(false);
                setCareersClick(false);
                setProductsClick(false);
                setContactsClick(false);
                setProjectsClick(true);
              }}
              className="myNav"
              style={{
                color: projectsClick ? "Red" : "white",
              }}
            >
              PROJECTS
            </NavLinks>
          </NavItem>

          <NavItem onClick={handleCareersClick} servicesClick={careersClick}>
            <NavLinks
              to="/careers"
              onClick={() => {
                closeMobileMenu();
                setHomeClick(false);
                setServicesClick(false);
                setProductsClick(false);
                setCareersClick(true);
                setContactsClick(false);
                setProjectsClick(false);
              }}
              className="myNav"
              style={{
                color: careersClick ? "Red" : "white",
              }}
            >
              CAREERS
            </NavLinks>
          </NavItem>
          <NavItem onClick={handleProductsClick} servicesClick={productsClick}>
            <NavLinks
              to="/products"
              onClick={() => {
                closeMobileMenu();
                setHomeClick(false);
                setServicesClick(false);
                setProductsClick(true);
                setCareersClick(false);
                setContactsClick(false);
                setProjectsClick(false);
              }}
              className="myNav"
              style={{
                color: productsClick ? "Red" : "white",
              }}
            >
              PRODUCTS
            </NavLinks>
          </NavItem>

          <NavItem onClick={handleContactsClick} servicesClick={contactsClick}>
            <NavLinks
              to="/contact"
              onClick={() => {
                closeMobileMenu();
                setHomeClick(false);
                setServicesClick(false);
                setProductsClick(true);
                setCareersClick(false);
                setContactsClick(true);
                setProjectsClick(false);
              }}
              className="myNav"
              style={{
                color: contactsClick ? "Red" : "white",
              }}
            >
              CONTACT
            </NavLinks>
          </NavItem>
        </NavMenu>
      </NavbarContainer>
    </Nav>
  );
}

export default Navbar;
