import React from "react";
import { InfoSectionTwo } from "../../components";
import {
  CustomBgSec,
  InfoColumn,
  TopLine,
  Container,
} from "./TeamSection.elements";
import { Col, Divider, Row } from "antd";
import { Heading } from "../InfoSection/InfoSection.elements";
import OurTeamCard from "../OurTeamCard/OurTeamCard";

function TeamSection(props) {
  return (
    <CustomBgSec>
      {/* Why choose us Section */}
      <Container>
        <InfoColumn>
          <TopLine style={{ color: "#fff" }}>
            <Row>
              <Col xs={11} sm={11} md={2} lg={2} style={{ marginTop: "15px" }}>
                OUR TEAM
              </Col>
              <Col xs={4} sm={4} md={1} lg={1}>
                <Divider
                  orientation="left"
                  style={{ borderTop: "3px solid red" }}
                />
              </Col>
            </Row>
          </TopLine>
          <Row>
            <Col xs={24} lg={24}>
              <Heading style={{ color: "#fff" }}>
                OUR MANAGEMENT BRINGS WEALTH OF EXPERIENCE AND EXPERTISE,
                PROVIDING GUIDANCE AND DIRECTION TO ACHIEVE OUR GOALS.
              </Heading>
            </Col>
          </Row>
          <TopLine style={{ color: "#fff", marginTop: "100px" }}>
            <Row justify="center">
              <Col xs={4} sm={4} md={2} lg={2} style={{ paddingRight: "20px" }}>
                <Divider
                  orientation="right"
                  style={{ borderTop: "3px solid red" }}
                />
              </Col>
              <Col xs={11} sm={11} md={4} lg={4} style={{ marginTop: "10px" }}>
                BOARD OF DIRECTORS
              </Col>
              <Col xs={4} sm={4} md={2} lg={2}>
                <Divider
                  orientation="left"
                  style={{ borderTop: "3px solid red" }}
                />
              </Col>
            </Row>
          </TopLine>
          <Row xs={24} lg={6} justify={"space-around"}>
            <Col>
              <OurTeamCard
                name={props[0].name}
                role={props[0].role}
                img={props[0].img}
                linkedinUrl={props[0].linkedinUrl}
              />
            </Col>
            <Col>
              <OurTeamCard
                name={props[1].name}
                role={props[1].role}
                img={props[1].img}
                linkedinUrl={props[1].linkedinUrl}
              />
            </Col>
            <Col>
              <OurTeamCard
                name={props[2].name}
                role={props[2].role}
                img={props[2].img}
                linkedinUrl={props[2].linkedinUrl}
              />
            </Col>
          </Row>
          <TopLine
            style={{ color: "#fff", marginTop: "100px", marginBottom: "20px" }}
          >
            <Row justify="center">
              <Col xs={4} sm={4} md={2} lg={2} style={{ paddingRight: "20px" }}>
                <Divider
                  orientation="right"
                  style={{ borderTop: "3px solid red" }}
                />
              </Col>
              <Col
                xs={11}
                sm={11}
                md={4}
                lg={4}
                style={{ marginTop: "10px", marginLeft: "20px" }}
              >
                MANAGEMENT TEAM
              </Col>
              <Col xs={4} sm={4} md={2} lg={2}>
                <Divider
                  orientation="left"
                  style={{ borderTop: "3px solid red" }}
                />
              </Col>
            </Row>
          </TopLine>
          <Row xs={24} lg={6} justify={"space-around"}>
            <Col>
              <OurTeamCard
                name={props[3].name}
                role={props[3].role}
                img={props[3].img}
                linkedinUrl={props[3].linkedinUrl}
              />
            </Col>
            <Col>
              <OurTeamCard
                name={props[1].name}
                role={props[1].role}
                img={props[1].img}
                linkedinUrl={props[1].linkedinUrl}
              />
            </Col>
            <Col>
              <OurTeamCard
                name={props[5].name}
                role={props[5].role}
                img={props[5].img}
                linkedinUrl={props[5].linkedinUrl}
              />
            </Col>
          </Row>
        </InfoColumn>
      </Container>

      <InfoSectionTwo />
    </CustomBgSec>
  );
}

export default TeamSection;
