import React from "react";
import Icon from "@ant-design/icons";
import { Container, DarkButton } from "../../globalStyles";
import { Item } from "../CustomBg/CustomBg.elements";
import { Col, Row, Divider } from "antd";
import { Link } from "react-router-dom";
import { Space } from "antd";
import { Collapse } from "antd";
import ItemComponent from "./ItemComponent";

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;
const items = [
  {
    key: "1",
    label: <h3>MEGAMATCHER ABIS 12.0 STARTING KIT</h3>,
    children: (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={6} lg={6}>
          <ItemComponent
            name="MegaMatcher ABIS 12.0
    Starting Kit"
            src="/item.png"
            srcModal="/item.png"
            details="MegaMatcher Automated Biometric Identification System (ABIS):
            MegaMatcher ABIS is a state-of-the-art biometric identification system designed for developers and integrators. It offers a range of solutions for different biometric identification purposes, including:
            High-speed and accurate transactions
            Customizable features to meet specific needs
            High availability and fault tolerance
            Scalable, modular architecture
            Biometric adjudication
            
            The modular architecture of MegaMatcher ABIS allows for each component to be scaled up to meet project requirements, including:
            Management service, which manages the transaction workflow
            Image processing service, which assesses and extracts biometric data from images
            Matching service, which stores and matches biometric data
            MegaMatcher ABIS API, a web services-based interface for communication with MegaMatcher units
            Adjudication application, designed for human experts to examine questionable results
            Administration Service, which manages and monitors the system using role-based access control
            Client Application, a platform-independent web-based application for key functionalities like enrollment, verification, and identification.
            
            Most components of MegaMatcher ABIS can be customized to meet specific project requirements, from user interface translation to modifying the system architecture."
          />
        </Col>
      </Row>
    ),
  },
  {
    key: "2",
    label: <h3>Megamatcher Accelerator SDK</h3>,
    children: (
      <>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={6} lg={6}>
            <ItemComponent
              name="Additional Megamatcher Accelerator 12.0 Extended"
              src="/item.png"
              srcModal="/item.png"
              details="MegaMatcher Automated Biometric Identification System (ABIS):
            MegaMatcher ABIS is a state-of-the-art biometric identification system designed for developers and integrators. It offers a range of solutions for different biometric identification purposes, including:
            High-speed and accurate transactions
            Customizable features to meet specific needs
            High availability and fault tolerance
            Scalable, modular architecture
            Biometric adjudication
            
            The modular architecture of MegaMatcher ABIS allows for each component to be scaled up to meet project requirements, including:
            Management service, which manages the transaction workflow
            Image processing service, which assesses and extracts biometric data from images
            Matching service, which stores and matches biometric data
            MegaMatcher ABIS API, a web services-based interface for communication with MegaMatcher units
            Adjudication application, designed for human experts to examine questionable results
            Administration Service, which manages and monitors the system using role-based access control
            Client Application, a platform-independent web-based application for key functionalities like enrollment, verification, and identification.
            
            Most components of MegaMatcher ABIS can be customized to meet specific project requirements, from user interface translation to modifying the system architecture."
            />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6}>
            <ItemComponent
              name="Additional Megamatcher Accelerator 12.0 Extreme"
              src="/item.png"
              srcModal="/item.png"
              details="MegaMatcher Automated Biometric Identification System (ABIS):
            MegaMatcher ABIS is a state-of-the-art biometric identification system designed for developers and integrators. It offers a range of solutions for different biometric identification purposes, including:
            High-speed and accurate transactions
            Customizable features to meet specific needs
            High availability and fault tolerance
            Scalable, modular architecture
            Biometric adjudication
            
            The modular architecture of MegaMatcher ABIS allows for each component to be scaled up to meet project requirements, including:
            Management service, which manages the transaction workflow
            Image processing service, which assesses and extracts biometric data from images
            Matching service, which stores and matches biometric data
            MegaMatcher ABIS API, a web services-based interface for communication with MegaMatcher units
            Adjudication application, designed for human experts to examine questionable results
            Administration Service, which manages and monitors the system using role-based access control
            Client Application, a platform-independent web-based application for key functionalities like enrollment, verification, and identification.
            
            Most components of MegaMatcher ABIS can be customized to meet specific project requirements, from user interface translation to modifying the system architecture."
            />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6}>
            <ItemComponent
              name="Additional Megamatcher Accelerator 12.0 Extended"
              src="/item.png"
              srcModal="/item.png"
              details="MegaMatcher Automated Biometric Identification System (ABIS):
            MegaMatcher ABIS is a state-of-the-art biometric identification system designed for developers and integrators. It offers a range of solutions for different biometric identification purposes, including:
            High-speed and accurate transactions
            Customizable features to meet specific needs
            High availability and fault tolerance
            Scalable, modular architecture
            Biometric adjudication
            
            The modular architecture of MegaMatcher ABIS allows for each component to be scaled up to meet project requirements, including:
            Management service, which manages the transaction workflow
            Image processing service, which assesses and extracts biometric data from images
            Matching service, which stores and matches biometric data
            MegaMatcher ABIS API, a web services-based interface for communication with MegaMatcher units
            Adjudication application, designed for human experts to examine questionable results
            Administration Service, which manages and monitors the system using role-based access control
            Client Application, a platform-independent web-based application for key functionalities like enrollment, verification, and identification.
            
            Most components of MegaMatcher ABIS can be customized to meet specific project requirements, from user interface translation to modifying the system architecture."
            />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6}>
            <ItemComponent
              name="Additional Megamatcher Accelerator 12.0 Standard"
              src="/item.png"
              srcModal="/item.png"
              details="MegaMatcher Automated Biometric Identification System (ABIS):
            MegaMatcher ABIS is a state-of-the-art biometric identification system designed for developers and integrators. It offers a range of solutions for different biometric identification purposes, including:
            High-speed and accurate transactions
            Customizable features to meet specific needs
            High availability and fault tolerance
            Scalable, modular architecture
            Biometric adjudication
            
            The modular architecture of MegaMatcher ABIS allows for each component to be scaled up to meet project requirements, including:
            Management service, which manages the transaction workflow
            Image processing service, which assesses and extracts biometric data from images
            Matching service, which stores and matches biometric data
            MegaMatcher ABIS API, a web services-based interface for communication with MegaMatcher units
            Adjudication application, designed for human experts to examine questionable results
            Administration Service, which manages and monitors the system using role-based access control
            Client Application, a platform-independent web-based application for key functionalities like enrollment, verification, and identification.
            
            Most components of MegaMatcher ABIS can be customized to meet specific project requirements, from user interface translation to modifying the system architecture."
            />
          </Col>
        </Row>
      </>
    ),
  },
  {
    key: "3",
    label: <h3>Megamatcher On Card SDK</h3>,
    children: (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={6} lg={6}>
          <ItemComponent name="MegaMatcher On Card 12.0 SDK" src="/item.png" srcModal="/item.png" details="MegaMatcher Automated Biometric Identification System (ABIS):
            MegaMatcher ABIS is a state-of-the-art biometric identification system designed for developers and integrators. It offers a range of solutions for different biometric identification purposes, including:
            High-speed and accurate transactions
            Customizable features to meet specific needs
            High availability and fault tolerance
            Scalable, modular architecture
            Biometric adjudication
            
            The modular architecture of MegaMatcher ABIS allows for each component to be scaled up to meet project requirements, including:
            Management service, which manages the transaction workflow
            Image processing service, which assesses and extracts biometric data from images
            Matching service, which stores and matches biometric data
            MegaMatcher ABIS API, a web services-based interface for communication with MegaMatcher units
            Adjudication application, designed for human experts to examine questionable results
            Administration Service, which manages and monitors the system using role-based access control
            Client Application, a platform-independent web-based application for key functionalities like enrollment, verification, and identification.
            
            Most components of MegaMatcher ABIS can be customized to meet specific project requirements, from user interface translation to modifying the system architecture." />
        </Col>
        <Col xs={24} sm={24} md={6} lg={6}>
          <ItemComponent name="MegaMatcher On Card 12.0 SDK" src="/item2.png" srcModal="/item2.png" details="MegaMatcher Automated Biometric Identification System (ABIS):
            MegaMatcher ABIS is a state-of-the-art biometric identification system designed for developers and integrators. It offers a range of solutions for different biometric identification purposes, including:
            High-speed and accurate transactions
            Customizable features to meet specific needs
            High availability and fault tolerance
            Scalable, modular architecture
            Biometric adjudication
            
            The modular architecture of MegaMatcher ABIS allows for each component to be scaled up to meet project requirements, including:
            Management service, which manages the transaction workflow
            Image processing service, which assesses and extracts biometric data from images
            Matching service, which stores and matches biometric data
            MegaMatcher ABIS API, a web services-based interface for communication with MegaMatcher units
            Adjudication application, designed for human experts to examine questionable results
            Administration Service, which manages and monitors the system using role-based access control
            Client Application, a platform-independent web-based application for key functionalities like enrollment, verification, and identification.
            
            Most components of MegaMatcher ABIS can be customized to meet specific project requirements, from user interface translation to modifying the system architecture."/>
        </Col>
      </Row>
    ),
  },
];

function LargeScale() {
  return (
    <Container style={{ marginBottom: "50px" }}>
      <Collapse items={items} defaultActiveKey={["1"]} />
    </Container>
  );
}

export default LargeScale;
