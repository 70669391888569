export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: "OUR PROJECTS",
  headline: "SOME BENEFITS OF JOINING BIOSEC",
  description:
    "Each project is carefully crafted to address specific business challenges and provide measurable results.",
  buttonLabel: "",
  imgStart: "",
  img: require("../../images/Timeline.png"),
  alt: "Credit Card",
};

export const homeObjTwo = {
  lightBg: false,
  lightText: true,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: "OUR STORY",
  headline:
    "BIOSEC SOLUTIONS EXCEEDS INDUSTRY STANDARDS WITH STATE-OF-THE-ART IDENTITY MANAGEMENT SOLUTIONS",
  description:
    "We have done so using an array of services ranging from core biometrics to enterprise software solutions and mobile applications. The Journey so far has been filled with remarkable and memorable moments that have been etched into our hearts and minds. We see it as extremely fitting to share some of those wonderful memories with you. ",
  buttonLabel: "",
  imgStart: "start",
  img: require("../../images/story.png"),
  alt: "Credit Card",
  start: "true",
};

export const careerTwo = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "WHAT WE OFFER",
  headline: "WE OFFER DIVERSE CUSTOMER TAILORED SERVICES",
  description:
    "At Biosec, we understand that one size does not fit all. That's why we offer diverse customer-tailored services to meet your individual needs and expectations. Discover the Biosec advantage with our personalized services. From identity management to enterprise architecture, we're here to serve your every requirement",
  buttonLabel: "Learn More",
  imgStart: "",
  img: require("../../images/svg-2.svg"),
  alt: "Vault",
  start: "",
};

export const careerThree = {
  lightBg: false,
  lightText: true,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: "CORE STRENGHTS",
  headline: "JOIN OUR TEAM TO INVENT THE FUTURE",
  description:
    "Each project is carefully crafted to address specific business challenges and provide measurable results.",
  buttonLabel: "",
  imgStart: "",
  img: require("../../images/svg-1.svg"),
  alt: "Credit Card",
};

export const careerHeader = {
  lightBg: false,
  lightText: true,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: "CORE STRENGHTS",
  headline: "LIFE AT BIOSEC SOLUTIONS",
  description:
    "At Biosec Solutions, we embrace diversity and aim to recruit from various walks of life. Guided by integrity, creativity, merit, and growth, we foster a culture of innovation, teamwork, and progression to help us reach our potential.",
  buttonLabel: "",
  imgStart: "start",
  img: require("../../assets/img/career.jpeg"),
  alt: "Credit Card",
};

export const mdInfo = {
  name: "DR. AGU OSOKA",
  role: "Managing Director",
  img: "/doc_new.jpg",
  linkedinUrl: "https://www.linkedin.com/in/agu-osoka-35a48a1/",
};

export const chairmanInfo = {
  name: "SIR OGALA OSOKA MFR, KJW",
  role: "Chairman",
  img: "/oga.png",
  linkedinUrl: "",
};

export const softwareEngineerInfo = {
  name: "FRANCIS ELROI",
  role: "Enterprise Software Engineer",
  img: "/francis.png",
  linkedinUrl: "",
};

export const securityEngineerInfo = {
  name: "MICHAEL AKINTOLA",
  role: "Network/Security Engineer",
  img: "/michael.png",
  linkedinUrl: "",
};

export const directorInfo = {
  name: "MR. ABDUL NDANUSA",
  role: "Director",
  img: "/abdul_new.png",
  linkedinUrl: "",
};

export const technologyDirectorInfo = {
  name: "IFEACHO NWOKOLO",
  role: "Director of Technology",
  img: "/acho_new.jpeg",
  linkedinUrl: "https://www.linkedin.com/in/ifeacho-nwokolo-96719928/",
};

export const projectManagerInfo = {
  name: "JYENITA ALASIA",
  role: "Director of Corporate Services",
  img: "/jyni.jpg",
  linkedinUrl: "https://www.linkedin.com/in/jyenita-alasia-4583b960/",
};

export const managementTeam = [
  chairmanInfo,
  mdInfo,
  directorInfo,
  technologyDirectorInfo,
  softwareEngineerInfo,
  projectManagerInfo,
  securityEngineerInfo,
];
