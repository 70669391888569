import React from "react";
import Icon from "@ant-design/icons";
import { Container, DarkButton } from "../../globalStyles";
import { Item } from "../CustomBg/CustomBg.elements";
import { Col, Row, Divider } from "antd";
import { Link } from "react-router-dom";
import { Space } from "antd";
import { Collapse } from "antd";
import ItemComponent from "./ItemComponent";

const items = [
  {
    key: "1",
    label: <h3>VERIFINGER SDKS</h3>,
    children: (
      <>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={6} lg={6}>
            <ItemComponent
              name="VeriFinger 12.0 Standard SDK"
              src="/finger.png"
              srcModal="/finger.png"
              details="No Details Available"
            />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6}>
            <ItemComponent
              name="VeriFinger 12.0 Extended SDK"
              src="/finger.png"
              srcModal="/finger.png"
              details="No Details Available"
            />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6}>
            <ItemComponent
              name="Additional Fingerprint Client component license(s)"
              src="/finger.png"
              srcModal="/finger.png"
              details="No Details Available"
            />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6}>
            <ItemComponent
              name="Additional Embedded Fingerprint Extractor component license(s) for iOS"
              src="/finger.png"
              srcModal="/finger.png"
              details="No Details Available"
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={6} lg={6}>
            <ItemComponent
              name="Additional Fingerprint Extractor component license(s)"
              src="/finger.png"
              srcModal="/finger.png"
              details="No Details Available"
            />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6}>
            <ItemComponent
              name="VeriFinger 12.0 Extended SDK"
              src="/finger.png"
              srcModal="/finger.png"
              details="No Details Available"
            />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6}>
            <ItemComponent
              name="Additional Fingerprint Matcher component license(s)"
              src="/finger.png"
              srcModal="/finger.png"
              details="No Details Available"
            />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6}>
            <ItemComponent
              name="Additional Embedded Fingerprint Matcher component license(s) for iOS"
              src="/finger.png"
              srcModal="/finger.png"
              details="No Details Available"
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={6} lg={6}>
            <ItemComponent
              name="Additional Embedded Fingerprint Extractor component license(s) for Android"
              src="/finger.png"
              srcModal="/finger.png"
              details="No Details Available"
            />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6}>
            <ItemComponent
              name="Additional Embedded Fingerprint Matcher component license(s) for Android"
              src="/finger.png"
              srcModal="/finger.png"
              details="No Details Available"
            />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6}>
            <ItemComponent
              name="Additional Embedded Fingerprint Client component license(s) for iOS"
              src="/finger.png"
              srcModal="/finger.png"
              details="No Details Available"
            />
          </Col>
        </Row>
      </>
    ),
  },
  
];

function Finger() {
  return (
    <Container style={{ marginBottom: "50px" }}>
      <Collapse items={items} defaultActiveKey={["1"]} />
    </Container>
  );
}

export default Finger;
