import React from "react";
import { TopLine, Heading, InfoSec } from "../InfoSection/InfoSection.elements";
import { VacancyBg } from "../Vacancy/VacancySection.element";
import { Col, Divider, Row } from "antd";
import { Container, LightButtonOutline, PlainText } from "../../globalStyles";

const VacancySection = ({ headline, big }) => {
  return (
    <>
      <VacancyBg>
        <center>
          <Heading>{headline}</Heading>
          <PlainText style={{ color: "black", fontSize: "23px" }}>
            Check back regularly for new vacancies.
          </PlainText>
        </center>
        <Container>
          {/* <center style={{ paddingBottom: "60px", paddingTop: "40px" }}>
            <Heading>{headline}</Heading>
            <Row justify="center" gutter={[15, 20]}>
              <Col xs={24} sm={24} md={8} lg={8}>
                <LightButtonOutline big>Technical Assistant</LightButtonOutline>
              </Col>

              <Col xs={24} sm={24} md={8} lg={8}>
                <LightButtonOutline big>
                  Administrative Assistant
                </LightButtonOutline>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                <LightButtonOutline big>Frontend Developer</LightButtonOutline>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                <LightButtonOutline big>Java Developer</LightButtonOutline>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                <LightButtonOutline big>Internship program</LightButtonOutline>
              </Col>
            </Row>
          </center> */}

          <Row>
            <Col xs={24} sm={24} md={24} lg={24}></Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <center>
                {/* <PlainText style={{ color: "black" }}>
                  Check back regularly for new vacancies.
                </PlainText> */}
              </center>
            </Col>
          </Row>
        </Container>
      </VacancyBg>
    </>
  );
};
export default VacancySection;
