import { Col, Row, Space } from "antd";
import React from "react";

import { PlainText } from "../../globalStyles";
import image1 from "../../images/Biosec_logo.svg";
import {
  FooterContainer,
  FooterInfo,
  FooterLink,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLinkTitle2,
  FooterLink2,
} from "./Footer.elements";
import "../../index.css";
import { Helmet } from "react-helmet";

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <FooterContainer>
        <Row style={{ borderBottom: "1px solid #fff" }}>
          <Col xs={24} sm={24} md={10} lg={10}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24}>
                <FooterInfo>
                  <Space direction="vertical" size={10}>
                    <img src={image1} alt="logo" />
                    <PlainText>
                      Biosec Solutions is an identity management consultancy
                      with over 13 years of cognate experience in providing
                      biometric solutions, smart card technologies, ID
                      verification, and token-based authentication to Nigerians.
                      Our goal is to provide high technology biometric solutions
                      to solve paramount identity management challenges in
                      Africa.
                    </PlainText>
                  </Space>
                </FooterInfo>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <FooterInfo style={{ marginTop: "30px", marginBottom: "30px" }}>
                  <Space direction="horizontal" size={30}>
                    <a href="/">
                      <i
                        class="bi bi-twitter"
                        style={{ fontSize: "2rem", color: "white" }}
                      ></i>
                    </a>
                    <a
                      href="https://web.facebook.com/BiosecSolutions"
                      target="blank"
                    >
                      <i
                        class="bi bi-facebook"
                        style={{ fontSize: "2rem", color: "white" }}
                      ></i>
                    </a>
                    <a
                      href="https://www.instagram.com/biosec.solutions"
                      target="blank"
                    >
                      <i
                        class="bi bi-instagram"
                        style={{ fontSize: "2rem", color: "white" }}
                      ></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/company/biosec-solutions?originalSubdomain=ng"
                      target="blank"
                    >
                      <i
                        class="bi bi-linkedin"
                        style={{ fontSize: "2rem", color: "white" }}
                      ></i>
                    </a>
                  </Space>
                </FooterInfo>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={14} lg={14}>
            <Row>
              <Col xs={12} sm={12} md={8} lg={8}>
                <FooterLinkItems>
                  <FooterLinkTitle>COMPANY</FooterLinkTitle>
                  <FooterLink2 to="/about">About</FooterLink2>
                  <FooterLink2 to="/projects">Projects</FooterLink2>
                  <FooterLink2 to="/services">Services</FooterLink2>
                  <FooterLink2 to="/careers">Careers</FooterLink2>
                  <FooterLink2 to="/contact">Contact</FooterLink2>
                </FooterLinkItems>
              </Col>
              <Col xs={12} sm={12} md={8} lg={8}>
                <FooterLinkItems>
                  <FooterLinkTitle>PRODUCTS</FooterLinkTitle>
                  <FooterLink2 to="/products">Biometric Hardware</FooterLink2>
                  <FooterLink2 to="/products">Biometric Software</FooterLink2>
                  <FooterLinkTitle>BIOSEC PROJECTS</FooterLinkTitle>
                  <FooterLink>
                    {" "}
                    <a
                      href="https://e-citizen.ng"
                      target="_blank"
                      className="mylink"
                      style={{ textDecoration: "none" }}
                    >
                      e-citizen™
                    </a>
                  </FooterLink>
                  <FooterLink to="#">NV-IDS</FooterLink>
                  <FooterLink>
                    <a
                      href="https://idcard.ng/"
                      target="_blank"
                      className="mylink"
                      style={{ textDecoration: "none" }}
                    >
                      idCard.ng
                    </a>
                  </FooterLink>
                </FooterLinkItems>
              </Col>
              <Col xs={12} sm={12} md={8} lg={8}>
                <FooterLinkItems>
                  <FooterLinkTitle>LEGAL</FooterLinkTitle>
                  <FooterLink>
                    <a
                      href="https://www.ndpc.gov.ng/"
                      target="_blank"
                      className="mylink"
                      style={{ textDecoration: "none" }}
                    >
                      NDPA Policy
                    </a>
                  </FooterLink>

                  <FooterLink>
                    {" "}
                    <a
                      href="https://gdpr-info.eu/"
                      target="_blank"
                      className="mylink"
                      style={{ textDecoration: "none" }}
                    >
                      GDPR Policy
                    </a>
                  </FooterLink>
                  <FooterLink>
                    {" "}
                    <a
                      href="https://biosec.com.ng/biosec-privacy-policy"
                      target="_blank"
                      className="mylink"
                      style={{ textDecoration: "none" }}
                    >
                      Privacy Policy
                    </a>
                  </FooterLink>
                  <FooterLink>
                    {" "}
                    <a
                      href="https://biosec.com.ng/BIosec-terms-of-service"
                      target="_blank"
                      className="mylink"
                      style={{ textDecoration: "none" }}
                    >
                      Terms of Service
                    </a>
                  </FooterLink>
                </FooterLinkItems>
              </Col>
            </Row>
          </Col>
        </Row>
        <center style={{ paddingTop: "20px", color: "#fff" }}>
          Biosec Solutions © {currentYear}. All Rights Reserved.
        </center>
      </FooterContainer>
    </>
  );
}

export default Footer;
