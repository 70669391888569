import React from "react";
import {
  InfoSec,
  InfoRow,
  InfoColumn,
  TextWrapper,
  Heading,
  Subtitle,
} from "./HeroSection.elements";
import {
  Container,
  LightButton,
  LightButtonOutlineHero,
} from "../../globalStyles";
import { Link } from "react-router-dom";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Space, Card, Image, Col, Row } from "antd";
import "../../index.css";
import { Carousel } from "antd";

const contentStyle = {
  height: "160px",
  paddingTop: "150px",
  color: "#fff",
  height: "550px",
  background: "transparent",
};

const HeroSection = ({
  primary,
  lightBg,
  topLine,
  lightTopLine,
  lightText,
  lightTextDesc,
  headline,
  headline2,
  description,
  description2,
  buttonLabel,
  buttonLabel2,
  img,
  alt,
  imgStart,
  start,
}) => {
  return (
    <>
      <div className="video-container">
        <video autoPlay loop muted playsInline className="background-video">
          <source src="/hero.mp4" type="video/mp4" />
        </video>

        <Row>
          <Col xs={24} sm={24} md={20} lg={20}>
            <Carousel autoplay>
              <div>
                <div style={contentStyle}>
                  <Container>
                    <InfoRow imgStart={imgStart}>
                      <InfoColumn>
                        <TextWrapper>
                          <Heading lightText={lightText}>{headline}</Heading>
                          <Subtitle lightTextDesc={lightTextDesc}>
                            {description}
                          </Subtitle>
                          <Link to="/contact">
                            <LightButton big primary={primary}>
                              <Space size={10} direction={"horizontal"}>
                                {buttonLabel} <ArrowRightOutlined />
                              </Space>
                            </LightButton>
                          </Link>
                        </TextWrapper>
                      </InfoColumn>
                    </InfoRow>
                  </Container>
                </div>
              </div>
              <div>
                <div style={contentStyle}>
                  <Container>
                    <InfoRow imgStart={imgStart}>
                      <InfoColumn>
                        <TextWrapper>
                          <Image
                            width={200}
                            src="/ecitiwgite.png"
                            style={{ marginBottom: "20px" }}
                          />
                          <Heading lightText={lightText}>
                            Secure Online Identity Verification
                          </Heading>
                          <Subtitle lightTextDesc={lightTextDesc}>
                            Say hello to the future of identity verification
                            with e-Citizen 2.0. Our platform provides a secure
                            and convenient way for Nigerians to verify their
                            identity online, anytime. Discover the power of
                            e-citizen™ 2.0 today.
                          </Subtitle>
                          <Link to="/contact">
                            <LightButton
                              big
                              style={{ background: "#09C93A", color: "#fff" }}
                            >
                              <Space size={10} direction={"horizontal"}>
                                Verify Identity <ArrowRightOutlined />
                              </Space>
                            </LightButton>
                          </Link>
                        </TextWrapper>
                      </InfoColumn>
                    </InfoRow>
                  </Container>
                </div>
              </div>
            </Carousel>
          </Col>
          <Col xs={0} sm={0} md={4} lg={4}>
            <Card
              bordered={false}
              style={{
                width: "auto",
                float: "right",
                marginTop: "250px",
              }}
            >
              <Image width={200} src="/eciti.png" />
              <h6 className="myaction">VERIFY NIGERIAN NATIONAL IDENTITY</h6>
            </Card>

            <div class="">
              <Link
                to="/contact"
                style={{
                  width: "auto",
                  float: "left",
                  marginTop: "20px",
                }}
              >
                <LightButtonOutlineHero>
                  Try it out <ArrowRightOutlined />
                </LightButtonOutlineHero>
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default HeroSection;
