import React from 'react';
import { ServicesBg } from './ServicesSection.elements';
import BiosuiteSection from './BiosuiteSection';
import { servicesList } from '../../pages/Services/Data';
import ServicesCardsSection from './ServicesCardsSection';

const ServicesBgSection = () => {
  return (
    <>
      <ServicesBg>
        <BiosuiteSection />
        <ServicesCardsSection {...servicesList} />
      </ServicesBg>
    </>
  );
};

export default ServicesBgSection;
